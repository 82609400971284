import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import Spacer from "../components/layout/Spacer";
import CareerFAQ from "../components/pages/Career/CareerFAQ";
import TableRow from "../components/pages/Career/TableRow";
import JobTable from "../components/pages/Career/JobTable";
import MainWrapper from "../components/pages/Career/MainWrapper";
import Footer from "../components/common/Footer";
import FAQcontnts from "../components/pages/Career/FAQcontents";
import CareerNavigation from "../components/pages/Career/CareerNavigation";
import CareerHeader from "../components/pages/Career/CareerHeader";
import Helmet from "react-helmet";

const CareerPage = () => {
  return (
    <Wrapper
      initial={{opacity: 0, y:50}}
      animate={{opacity: 1, y: 0}}
      transition={{duration: 1}}
      exit={{opacity: 0, y:50}}
    >
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Noto+Sans:wght@400;600;700&display=swap');
        </style>
      </Helmet>
      <CareerHeader
        bg_img={
          <StaticImage
            src="../images/career_top.jpg"
            alt="career header image"
            layout="fullWidth"
            style={{ height: "42.5vw", maxHeight: "612px" }}
          />
        }
      >
        <h1>Career</h1>
        <div className="line"></div>
        <Spacer height="min(46px, 3.194vw)" />
        <p>iTipsは、共に働く仲間を探しています。</p>
      </CareerHeader>

      <Spacer height="min(5vw, 150px)" />

      <MainWrapper>
        <h1>JOIN US</h1>
        <p className="subtitle">採用情報</p>        

        <JobTable>
          <h2 id="technology">採用・業務委託・インターン</h2>
          <table>
            <tbody>
              <TableRow
                job={"職人育成事業"}
                sub={"インドにて「日本式モノづくり学校」の立ち上げ"}
                content={
                  "業務例：\n・日本式ものづくり学校設立準備全般 \n・日印両政府関係者との協議 \n・訓練プログラム開発"
                }
              />
              
            </tbody>

            <tbody>
              <TableRow
                job={"アプリ開発及びインドIT開発チーム構築"}
                sub={"6G時代の革新的オンライン研修プログラム開発に向けた開発チーム構築"}
                content={
                  "業務例：\n・職人勤怠管理アプリの開発 \n・インドIT人材の採用、チームビルディング"
                }
              />
              
            </tbody>

            <tbody>
              <TableRow
                job={"人事総務経理"}
                sub={"戦略的に人材、資金リソースを社内に取り込み、急成長につなげる"}
                content={
                  "業務例：\n・財務戦略構築と実行　\n・人材採用戦略立案と実行"
                }
              />
              
            </tbody>


          </table>
        </JobTable>

        
        
      </MainWrapper>

      <Spacer height={"60px"}></Spacer>
{/*
      <CareerFAQ>
        <h1>FAQ</h1>
        <p className="subtitle">よくある質問</p>

        <Spacer height={"20px"}></Spacer>

        <div className="border"></div>
        <FAQcontnts
          question="Q. 選考プロセスを教えてください"
          answer="A. ES　一次面接　二次面接です"
        ></FAQcontnts>
        <div className="border"></div>

        <FAQcontnts
          question="Q. チーム構成について教えてください"
          answer="A. "
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="Q. 複数ポジションに同時に応募することはできますか？"
          answer="A. "
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="Q. 現在の働き方が知りたい　（リモートワーク・フレックスタイム制について）"
          answer="A. "
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="Q. 入社前にはどのようなサポートが受けられますか？"
          answer="A. "
        ></FAQcontnts>
        <div className="border"></div>
      </CareerFAQ>
              
      <Spacer height={"200px"}></Spacer>
*/}
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  .career-body {
    width: 80%;
    margin: 0 auto;
  }
`;

export default CareerPage;
