import React from "react";
import styled from "@emotion/styled";

interface Props {
  bg_img: React.ReactNode;
  children: React.ReactNode;
}

const CareerHeader = (props: Props) => {
  const { bg_img, children } = props;

  return (
    <Wrapper>
      {bg_img}
      <div className="header-content">{children}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 58.125vw;
  max-height: 837px;
  position: relative;

  .header-content {
    position: absolute;
    top: min(580px, 40vw);
    left: 10%;
    width: 80%;
    text-align: center;

    h1 {
      font-size: max(40px, 6.944vw);
      color: #333333;
      margin: 0;
    }

    .line {
      background-color: #d9d9d9;
      width: 16.458vw;
      max-width: 237px;
      height: 0.625vw;
      max-height: 9px;
      margin: 0 auto;
    }
    p {
      color: #828282;
      font-size: min(18px, 1.25vw);
    }

    @media screen and (max-width: 768px) {
      .header-content {
        left: 10%;
        width: 80%;
      }
    }
  }
`;

export default CareerHeader;
